

import { TailSpin } from 'react-loader-spinner'
import React from 'react'

function LoadinSpinner(props) {
  return (
    <TailSpin
      height={!props.height ? "20" : props.height}
      width={!props.width ? "20" : props.width}
      color={props.color}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  )
}

export default LoadinSpinner