import { ApolloClient, InMemoryCache } from '@apollo/client'


const createApolloClient = token => {
    return new ApolloClient({
        uri: process.env.REACT_APP_HASURA_URL,
        headers: {
            Authorization: `Bearer ${token}`,
            // 'x-hasura-admin-secret': 'grendene32145!klioaksjeidk',
            // 'content-type': 'application/json',
        },
        cache: new InMemoryCache(),
    });


}
export default createApolloClient
