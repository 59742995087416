export const options = [
    { value: 'pt', label: 'Português (BR)' },
    { value: 'es', label: 'Espanhol (ES)' },
    { value: 'en', label: 'English (EN)' }
]
export const styleSelect = {

    control: (styles) => {
        return {
            ...styles,
            backgroundColor: "transparent",
            border: "0"
        }

    },
    indicatorSeparator: (styles) => {
        return {
            ...styles,
            color: "#3542C2",
            backgroundColor: "transparent"
        }
    },

    indicatorsContainer: (styles) => {
        return {
            ...styles,
            color: "#3542C2",

            ':hover': {
                color: "#3542C2"
            },
        }
    },
    dropdownIndicator: (provided) => ({
        ...provided,

        "svg": {

            fill: "#3542C2",
        }
    }),
    placeholder: (styles) => {
        return {
            ...styles,
            color: "#3542C2"
        }

    },
    container: (styles) => {
        return {
            ...styles,
            minWidth: "180px"
        }
    },
    singleValue: (styles, { data }) => {
        return {
            ...styles,
            color: "#3542C2",

        }
    }
}