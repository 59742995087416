

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import AppBar from '../../components/AppBar'
import Preview from '../../components/Preview'
import Register from '../../components/Register'
import { setPreviewImage } from '../../redux/slices/userSlice'
import { getByUserImg } from '../../service/fileUtils'
import { getUserId } from '../../auth'
import { useQuery } from '@apollo/client'
import { GET_BY_USER_ID } from '../../gql/user'
import LoadinSpinner from '../../components/LoadinSpinner'

function Home() {

  const userRedux = useSelector((state) => state);
  const [user, setUser] = useState({})
  const dispatch = useDispatch()

  const { data, loading, error } = useQuery(GET_BY_USER_ID, {
    variables: {
      id: getUserId()
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (data) {
      switch (userRedux.typeLanguage) {
        case "pt":
          if (Object.keys(data.employees).length > 0)
            setUser(data.employees[0])
          break;
        case "en":
          if (Object.keys(data.employees_en).length > 0) {
            setUser(data.employees_en[0])
          } else {
            setUser({ ...data.employees[0], language: "en" })
          }
          break;
        case "es":
          if (Object.keys(data.employees_es).length > 0) {
            setUser(data.employees_es[0])
          } else {
            setUser({ ...data.employees[0], language: "es" })
          }
          break;
      }
      if (data.employees.length > 0) {
        getByUserImg(data.employees[0].image).then(response => {
          dispatch(setPreviewImage(URL.createObjectURL(response.data)))
        }).catch(error => {
          console.log(error)
        })

      }
    }
  }, [data, userRedux.typeLanguage])
  if (loading) return <>
    <div style={{
      background: 'rgb(53 66 194 / 15%)',
      width: "100vw",
      height: "100vh"
    }} className='d-flex justify-content-center align-items-center'>
      <LoadinSpinner color='black'
        height="75"
        width="75"
      />
    </div>

  </>;
  if (error) return window.location.replace("/");


  return (
    <>
      <AppBar />
      <Row style={{ margin: "0", height: "94vh" }}>
        <Col md={6} style={{ margin: "0", padding: "0", backgroundColor: "#F2F2F2" }}>
          <Register userInfo={user} />
        </Col>
        <Col md={6} style={{ margin: "0", padding: "0" }}>
          <Preview userInfo={user} />
        </Col>
      </Row>
    </>
  )
}

export default Home