import React, { useState, useEffect } from "react";

import { Col, Form, Input, Row, InputGroupText, InputGroup, Tooltip, Label, FormGroup } from "reactstrap";
import { BodyRegister, ButtonPrimary, FormArea, LabelRegister, Picture } from "./style";

import picture from "../images/picture.svg"
import edit from "../images/Grupo_25.svg"
import { IoMdWarning, IoWarning } from "react-icons/io"
import { SlInfo } from 'react-icons/sl'
import LoadinSpinner from "./LoadinSpinner";
import { useMutation } from "@apollo/client";
import { GET_BY_USER_ID, INSERT_USER_EN, INSERT_USER_ES, UPDATE_USER_EN, UPDATE_USER_ES, UPDATE_USER_PT } from "../gql/user";
import { getByUserImg, minioUploadFile } from "../service/fileUtils";
import { useDispatch, useSelector } from "react-redux";
import { setDepartment, setPreviewImage, setLogo, setSaveContactOptions, setRole, setUsername, setLinkedin, setEmail, setPhone, setUnity, setWhatsapp, setLoadingForm, setTypeLanguage } from "../redux/slices/userSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserId } from "../auth";
import { optionsLogos } from "../constants/logo";
import { saveContactOptions } from "../constants/saveContactsOptions";

function Register({ userInfo }) {
    const [image, setImage] = useState()
    const { t, i18n } = useTranslation('common')
    const userRedux = useSelector(state => state)
    const dispatch = useDispatch()

    const [whatsappTooltipOpen, setWhatsappTooltipOpen] = useState(false);
    const toggleWhatsapp = () => setWhatsappTooltipOpen(!whatsappTooltipOpen);
    
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const [saveContactTooltipOpen, setSaveContactTooltipOpen] = useState(false)
    const toggleSaveContact = () => setSaveContactTooltipOpen(!saveContactTooltipOpen)

    const [userUpdatePt] = useMutation(UPDATE_USER_PT, {
        refetchQueries: [
            {
                query: GET_BY_USER_ID,
                variables: { id: getUserId() }
            }
        ]
    })
    const [userUpdateEs] = useMutation(UPDATE_USER_ES, {
        refetchQueries: [
            {
                query: GET_BY_USER_ID,
                variables: { id: getUserId() }
            }
        ]
    })
    const [userUpdateEn] = useMutation(UPDATE_USER_EN, {
        refetchQueries: [
            {
                query: GET_BY_USER_ID,
                variables: { id: getUserId() }
            }
        ]
    })
    const [insertEn] = useMutation(INSERT_USER_EN, {
        refetchQueries: [
            {
                query: GET_BY_USER_ID,
                variables: { id: getUserId() }
            }
        ]
    })
    const [insertEs] = useMutation(INSERT_USER_ES, {
        refetchQueries: [
            {
                query: GET_BY_USER_ID,
                variables: { id: getUserId() }
            }
        ]
    })

    const handleFile = (event) => {
        const { files } = event.target
        dispatch(setPreviewImage(URL.createObjectURL(files[0])));
        setImage(files[0])
    }

    const handleWhats = (event) => {
        const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
        var str = event.target.value.replace(/[^0-9]/g, "").slice(0, 11);

        const result = str.replace(regex, "($1) $2-$3");

        dispatch(setWhatsapp(result));
    }
    const handleSelectLogo = (event) => {

        if (t('preview.logo') != event.target.value) {
            let tempFindLogo = optionsLogos.find(logo => logo.id == event.target.value)
            dispatch(setLogo(tempFindLogo))
        } else {
            dispatch(setLogo(null))
        }

    }

    const handleSaveContactOptions = (event) => {
        if (t('preview.save-contact') != event.target.value) {
            let tempSaveContactOption = saveContactOptions.find(option => option.value == event.target.value)
            
            if (tempSaveContactOption.value === "PHONE_NUMBER" && !userRedux.phone) {
                toast.error(t("form.missing-phone-field"))
                dispatch(setSaveContactOptions({ label: null, value: "default" }))

                return
            }

            if (tempSaveContactOption.value === "WHATSAPP" && !userRedux.whatsapp) {
                toast.error(t("form.missing-whatsapp-field"))
                dispatch(setSaveContactOptions({ label: null, value: "default" }))

                return
            }
            
            dispatch(setSaveContactOptions(tempSaveContactOption))
        } else {
            dispatch(setSaveContactOptions(null))
        }
    }

    async function submitUpdateUser(event) {

        try {
            event.preventDefault()
            dispatch(setLoadingForm(true))

            let userInfoUpdates = {
                name: userRedux.username ? userRedux.username : userInfo.name,
                department: userRedux.department ? userRedux.department : userInfo.department,
                email: userRedux.email ? userRedux.email : userInfo.email,
                image: image,
                linkedin: userRedux.linkedin,
                phone: userRedux.phone,
                role: userRedux.role ? userRedux.role : userInfo.role,
                unity: userRedux.unity ? userRedux.unity : userInfo.unity,
                whatsapp: userRedux?.whatsapp?.replace(/[^0-9]/g, ""),
                save_contact_with: userRedux?.saveContactOption?.value ? userRedux.saveContactOption.value : userInfo.save_contact_with
            }

            const tempUser = { ...userInfoUpdates };
            delete tempUser.linkedin;
            delete tempUser.whatsapp;
            delete tempUser.image;

            if (Object.values(tempUser).includes(undefined)) {
                dispatch(setLoadingForm(false))
                return toast.error(t('form.unfilledFields'));
            }

            if (image) {
                const responseFile = await minioUploadFile(image, 'user').catch(err => {
                    console.log(err)
                })
                const { data } = responseFile

                userInfoUpdates.image = data.data[0].key
            }

            if (!userInfo.id) {
                dispatch(setLoadingForm(false))
                return toast.error(t('form.uninformedUserAlert'))
            }
            //adicionar os metodos de atualizar em es em en e adicionar 
            const insertUserInfo = { ...userInfoUpdates, user_id: userInfo.user_id, id: userInfo.id }
            if (!userInfo.language) {
                switch (userRedux.typeLanguage) {
                    case "pt":
                        userUpdatePt({
                            variables: {
                                id: userInfo.id,
                                _set: userInfoUpdates
                            }
                        }).then(resp => {

                            toast.success(t('form.successAlert'))
                            dispatch(setLoadingForm(false))
                        }).catch(err => {
                            toast.error(t('form.errorAlert'))
                            dispatch(setLoadingForm(false))
                        })
                        break;
                    case "en":
                        userUpdateEn({
                            variables: {
                                id: userInfo.id,
                                _set: userInfoUpdates
                            }
                        }).then(resp => {

                            toast.success(t('form.successAlert'))
                            dispatch(setLoadingForm(false))
                        }).catch(err => {
                            toast.error(t('form.errorAlert'))
                            dispatch(setLoadingForm(false))
                        })
                        break;
                    case "es":
                        userUpdateEs({
                            variables: {
                                id: userInfo.id,
                                _set: userInfoUpdates
                            }
                        }).then(resp => {

                            toast.success(t('form.successAlert'))
                            dispatch(setLoadingForm(false))
                        }).catch(err => {
                            toast.error(t('form.errorAlert'))
                            dispatch(setLoadingForm(false))
                        })
                        break;
                }
            } else {
                switch (userInfo.language) {
                    case "en":

                        insertEn({
                            variables: {
                                object: insertUserInfo
                            }
                        }).then(resp => {

                            toast.success(t('form.successAlert'))
                            dispatch(setLoadingForm(false))
                        }).catch(err => {
                            toast.error(t('form.errorAlert'))
                            dispatch(setLoadingForm(false))
                        })
                        break;
                    case "es":


                        insertEs({
                            variables: {
                                object: insertUserInfo
                            }
                        }).then(resp => {

                            toast.success(t('form.successAlert'))
                            dispatch(setLoadingForm(false))
                        }).catch(err => {
                            toast.error(t('form.errorAlert'))
                            dispatch(setLoadingForm(false))
                        })
                        break;
                }
            }


            // userUpdatePt({
            //     variables: {
            //         id: userInfo.id,
            //         _set: userInfoUpdates
            //     }
            // }).then(resp => {

            //     toast.success(t('form.successAlert'))
            //     dispatch(setLoadingForm(false))
            // }).catch(err => {
            //     toast.error(t('form.errorAlert'))
            //     dispatch(setLoadingForm(false))
            // })

        } catch (err) {
            console.error(err)
            dispatch(setLoadingForm(false))
        }


    }

    useEffect(() => {
        dispatch(setUsername(userInfo.name))
        dispatch(setUnity(userInfo.unity))
        dispatch(setDepartment(userInfo.department))
        dispatch(setEmail(userInfo.email))
        dispatch(setLinkedin(userInfo.linkedin))
        dispatch(setWhatsapp(userInfo.whatsapp))
        dispatch(setPhone(userInfo.phone))
        dispatch(setRole(userInfo.role))
        dispatch(setSaveContactOptions({ label: userInfo.save_contact_with, value: userInfo.save_contact_with }))
    }, [userRedux.typeLanguage, userInfo])
    
    return (
        <>
            <h1></h1>
            <Form className="m-0 p-3" onSubmit={submitUpdateUser}>
                <Row className="m-0">
                    <Col md={12}>

                        <label style={{ cursor: 'pointer' }} onChange={handleFile} htmlFor="formId">
                            <Input name="formId" type="file" id="formId" hidden />

                            {userRedux.previewImage ? (
                                <>
                                    <Picture
                                        style={{ borderRadius: "50%", border: '3px solid #19227D' }}
                                        src={userRedux.previewImage}
                                    />
                                    <img
                                        src={edit}
                                        style={{ marginTop: '5em', marginLeft: '-2em' }}
                                        alt=""
                                    />
                                </>
                            ) :
                                <Picture src={picture} />
                            }


                        </label>
                    </Col>
                    <Col md={6}>
                        <FormArea>
                            <LabelRegister for="fullName">
                                {t('form.name')}
                            </LabelRegister>
                            <Input
                                defaultValue={userRedux.username || userInfo.name}
                                value={userRedux.username}
                                id="fullName"
                                name="fullName"
                                type="text"
                                required
                                onChange={e => { dispatch(setUsername(e.target.value)) }}
                            />
                        </FormArea>
                    </Col>
                    <Col md={6}>
                        <FormArea>
                            <LabelRegister for="cargo"  className="text-capitalize">
                                {t('form.office')}
                            </LabelRegister>
                            <Input
                                defaultValue={userRedux.role || userInfo.role}
                                value={userRedux.role}
                                id="cargo"
                                name="cargo"
                                type="text"
                                required
                                onChange={e => dispatch(setRole(e.target.value))}
                            />
                        </FormArea>
                    </Col>
                    <Col md={6}>
                        <FormArea>
                            <LabelRegister for="setor" className="text-capitalize">
                                {t('form.department')}
                            </LabelRegister>
                            <Input
                                defaultValue={userRedux.department || userInfo.department}
                                id="setor"
                                name="setor"
                                type="text"
                                required
                                value={userRedux.department}
                                onChange={e => dispatch(setDepartment(e.target.value))}
                            />
                        </FormArea>
                    </Col>
                    <Col md={6}>
                        <FormArea>
                            <LabelRegister for="unidade"  className="text-capitalize">
                                {t('form.unity')}
                            </LabelRegister>
                            <Input
                                defaultValue={userRedux.unity || userInfo.unity}
                                value={userRedux.unity}
                                id="unidade"
                                name="unidade"
                                required
                                type="text"
                                onChange={e => dispatch(setUnity(e.target.value))}
                            />
                        </FormArea>
                    </Col>
                    <Col md={6}>
                        <FormArea>
                            <LabelRegister for="fone"  className="text-capitalize">
                                {t('form.telephone')}
                            </LabelRegister>
                            <Input
                                id="fone"
                                name="fone"
                                type="tel"
                                value={userRedux.phone}
                                defaultValue={userRedux.phone || userInfo.phone}
                                onChange={e => dispatch(setPhone(e.target.value))}
                            />
                        </FormArea>
                    </Col>
                    <Col md={6}>
                        <FormArea>
                            <LabelRegister for="whatsapp" className="text-capitalize">
                                {t('form.whatsapp')}

                                <SlInfo size={20} id="whatsapp-alert" color="info" className="mx-2" />
                                <Tooltip
                                    isOpen={whatsappTooltipOpen}
                                    target="whatsapp-alert"
                                    toggle={toggleWhatsapp}
                                >
                                    {t('form.whatsapp-alert')}
                                </Tooltip>
                            </LabelRegister>
                            <InputGroup>
                                <InputGroupText>
                                    +55
                                </InputGroupText>
                                <Input
                                    id="whatsapp"
                                    name="whatsapp"
                                    type="text"
                                    value={userRedux.whatsapp}
                                    defaultValue={userRedux.whatsapp || userInfo.whatsapp}
                                    onChange={handleWhats}
                                />
                            </InputGroup>
                        </FormArea>
                    </Col>
                    <Col md={6}>
                        <FormArea>
                            <LabelRegister for="email"  className="text-capitalize">
                                {t('form.email')}
                            </LabelRegister>
                            <InputGroup>
                                <InputGroupText
                                    id="duvidas"
                                >
                                    <SlInfo size={20} color="info" />
                                </InputGroupText>
                                <Input
                                    defaultValue={userRedux.email || userInfo.email}
                                    onChange={e => dispatch(setEmail(e.target.value))}
                                    id="email"
                                    name="email"
                                    disabled
                                    type="email"
                                />
                                <Tooltip
                                    isOpen={tooltipOpen}
                                    target="duvidas"
                                    toggle={toggle}
                                >
                                    {t('form.alert')}
                                </Tooltip>
                            </InputGroup>
                        </FormArea>
                    </Col>
                   
                    {userRedux.tab === '2' && (
                        <Col md={6}>
                            <FormArea>
                                <LabelRegister for="logos"  className="text-capitalize">
                                    {t('form.logos')}
                                </LabelRegister>
                                <Input
                                    id="logo"
                                    name="logos"
                                    type="select"
                                    onChange={handleSelectLogo}
                                >
                                    {optionsLogos.map(logos => {
                                        return (
                                            <option value={logos.id}>
                                                {logos.name == 'default' ? t('preview.logo') : logos.name}
                                            </option>
                                        )
                                    })}
                                </Input>
                            </FormArea>
                        </Col>
                    )}

                    {userRedux.tab === '1' && (
                        <Col md={6}>
                            <FormArea>
                                <LabelRegister for="save-contact-options"  className="text-capitalize">
                                    {t('form.save-contact-options')}

                                    <SlInfo size={20} id="save-contact-alert" color="info" className="mx-2" />
                                    <Tooltip
                                        isOpen={saveContactTooltipOpen}
                                        target="save-contact-alert"
                                        toggle={toggleSaveContact}
                                    >
                                        {t('form.save-contact-alert')}
                                    </Tooltip>
                                </LabelRegister>
                                <Input
                                    id="save-contact-options"
                                    name="save-contact-options"
                                    type="select"
                                    onChange={handleSaveContactOptions}
                                    value={userRedux.saveContactOption?.value}
                                >
                                    {saveContactOptions.map(option => {
                                        return (
                                            <option value={option.value}>
                                                {option.value === "default" ? t('preview.save-contact') : option.label}
                                                {/* {option.name == 'default' ? t('preview.logo') : option.name} */}
                                            </option>
                                        )
                                    })}
                                </Input>
                            </FormArea>
                        </Col>
                    )}

                </Row>
                <Row className="p-4 mt-5 justify-content-end text-capitalize">
                    <ButtonPrimary
                        type="submit"
                        disabled={userRedux.loadingForm}
                        style={{ width: "120px" }}>

                        {userRedux.loadingForm ? <div className="d-flex justify-content-center"> <LoadinSpinner color='white' /> </div> : t('form.button')}
                    </ButtonPrimary>

                </Row>

            </Form>
        </>
    )
}

export default Register