import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  linkedin: null,
  whatsapp: null,
  previewImage: null,
  loadingForm: false,
  loadingQr: false,
  username: "",
  role: "",
  department: "",
  unity: "",
  email: "",
  phone: "",
  typeLanguage: "pt",
  tab: "1",
  logo: {},
  saveContactOption: null,
  save_contact_with: ""
}
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSaveContactOptions(state, action) {
      state.saveContactOption = action.payload;
    },
    setPreviewImage(state, action) {
      state.previewImage = action.payload
    },
    setTab(state, action) {
      state.tab = action.payload
    },
    setLogo(state, action) {
      state.logo = action.payload
    },
    setLoadingForm(state, action) {
      state.loadingForm = action.payload
    },
    setTypeLanguage(state, action) {
      state.typeLanguage = action.payload
    },
    setLoadingQr(state, action) {
      state.loadingQr = action.payload
    },
    setDepartment(state, action) {
      state.department = action.payload
    },
    setUnity(state, action) {
      state.unity = action.payload
    },
    setEmail(state, action) {
      state.email = action.payload
    },
    setPhone(state, action) {
      state.phone = action.payload
    },
    setRole(state, action) {
      state.role = action.payload
    },
    setUsername(state, action) {
      state.username = action.payload
    },
    setWhatsapp(state, action) {
      state.whatsapp = action.payload
    },
    setLinkedin(state, action) {
      state.linkedin = action.payload
    },
    resetState(state) {
      state = initialState
    }
  }
})
export const {
  resetState,
  setUsername,
  setRole,
  setEmail,
  setPreviewImage,
  setPhone,
  setLoadingForm,
  setUnity,
  setLogo,
  setTab,
  setDepartment,
  setLinkedin,
  setLoadingQr,
  setTypeLanguage,
  setWhatsapp,
  setSaveContactOptions
} = userSlice.actions

export default userSlice.reducer