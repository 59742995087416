import { gql } from "@apollo/client"

export const UPDATE_USER_PT = gql`
mutation UpdateUserInfoPt($_set: employees_set_input, $id: uuid!) {
  update_employees_by_pk(_set: $_set, pk_columns: {id: $id}) {
    name
    department
    email
    image
    linkedin
    phone
    role
    unity
    whatsapp
  }
}

`
export const UPDATE_USER_ES = gql`
mutation UpdateUserInfoEs($_set: employees_es_set_input, $id: uuid!) {
  update_employees_es_by_pk(_set: $_set, pk_columns: {id: $id}) {
    name
    department
    email
    image
    linkedin
    phone
    role
    unity
    whatsapp
  }
}

`
export const INSERT_USER_ES = gql`
mutation InsertUserEs($object: employees_es_insert_input!) {
  insert_employees_es_one(object: $object) {
    id
    image
    linkedin
    name
    phone
    role
    unity
    user_id
    whatsapp
    email
    department
  }
}
`
export const INSERT_USER_EN = gql`
mutation InsertUserEn($object: employees_en_insert_input!) {
  insert_employees_en_one(object: $object) {
    id
    image
    linkedin
    name
    phone
    role
    unity
    user_id
    whatsapp
    email
    department
  }
}

`
export const UPDATE_USER_EN = gql`
mutation UpdateUserInfoEn($_set: employees_en_set_input, $id: uuid!) {
  update_employees_en_by_pk(_set: $_set, pk_columns: {id: $id}) {
    name
    department
    email
    image
    linkedin
    phone
    role
    unity
    whatsapp
  }
}

`
export const GET_BY_USER_ID = gql`
query MyQuery($id: uuid!) {
  employees(where: {id: {_eq: $id}}) {
    department
    email
    id
    image
    linkedin
    name
    phone
    role
    unity
    whatsapp
    user_id
    save_contact_with
  }
  employees_en(where: {id: {_eq: $id}}) {
    department
    email
    id
    image
    linkedin
    name
    phone
    role
    unity
    whatsapp
    user_id
    save_contact_with
  }
  employees_es(where: {id: {_eq: $id}}) {
    department
    email
    id
    image
    linkedin
    name
    phone
    role
    unity
    whatsapp
    user_id
    save_contact_with
  }
}

`