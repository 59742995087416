
export const saveContactOptions = [
    {
        label: "Selecione um contato",
        value: "default"
    },
    {
        label: "Telefone",
        value: "PHONE_NUMBER"
    },
    {
        label: "Whatsapp",
        value: "WHATSAPP"
    }
];
