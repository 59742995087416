import "bootstrap/dist/css/bootstrap.min.css";
import domtoimage from "dom-to-image";
import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import BlankProfile from "../images/blankProfile.png";
import phone from "../images/fone.svg";
import SafeContact from "../images/Vector.svg";
import logoGrendene from "../images/logoGrendene.svg";
import logoGrendenePreta from "../images/logoGrendenePreta.svg";
import msg from "../images/msg.svg";
import whats from "../images/whats.svg";
import {
  setLoadingQr,
  setTab,
  setTypeLanguage,
} from "../redux/slices/userSlice";
import LoadinSpinner from "./LoadinSpinner";
import {
  BodyPreview,
  BusinessCard,
  ButtonPrimary,
  ContentBusinessCard,
  HeaderBusinessCard,
  HeaderPreview,
  ImageArea,
  ImgCircle,
  InfoItems,
  InfoItemsArea,
  InfoUserArea,
  LabelInfo,
  LogoGrendene,
  QrArea,
  QrAreaWhite,
  QrCard,
  Questions,
  TitlePreview,
} from "./style";
import { useTranslation } from "react-i18next";
import { options, styleSelect } from "../constants/language";
import LogoEUA from "../images/bandeira_eua.svg";
import LogoEspanha from "../images/bandeira-espanha_1.svg";
import LogoBrasil from "../images/bandeira-do-brasil_1.svg";
import { stylesGrendene, stylesLogos } from "../constants/logo";

async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export default function Preview({ userInfo }) {
  const [activeTab, setActiveTab] = React.useState("1");
  const userRedux = useSelector((state) => state);
  const [languageActual, setlanguageActual] = useState("");
  const [onlyQrCode, setOnlyQrCode] = useState(false);
  const [removeQrCode, setRemoveOnlyQrCode] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("common");

  // Refs
  const textAreaRef = React.useRef(null);

  const toggle = (tab) => {
    if (userRedux.tab !== tab) dispatch(setTab(tab));
  };

  const downloadSignature = async (e) => {
    dispatch(setLoadingQr(true));
    // const qrCode64 = document.getElementById("canvasQr").toDataURL("image/png");
    // let html = htmlSignature;
    // html = html.split("{{imgGrendene}}").join(imgGrendeneBase64);
    // html = html.split("{{qrcode}}").join(qrCode64);
    // html = html.split("{{role}}").join(userRedux.role ? userRedux.role : userInfo.role);
    // html = html.split("{{unity}}").join(userRedux.unity ? userRedux.unity : userInfo.unity);
    // html = html
    //   .split("{{department}}")
    //   .join(userRedux.department ? userRedux.department : userInfo.department);
    // html = html.split("{{nome}}").join(userRedux.username ? userRedux.username : userInfo.name);
    // html.split("{{whatsapp}}").join(userRedux.whatsapp ? userRedux.whatsapp : userInfo.whatsapp);
    let elementCopy = document.querySelector("#qrCode");

    // if (!removeQrCode) {


    //   elementCopy.style.marginLeft = "0.15em";
    // } else {
    //   let tempElementCopyQr = document.querySelector("#semQr");
    //   tempElementCopyQr.style.background = "#F2F2F2 0% 0% no-repeat padding-box"

    // }
    domtoimage
      .toPng(document.querySelector("#qrCode"), { quality: 0.95, bgcolor: "white" })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "assinatura.png";
        link.href = dataUrl;
        link.click();
        toast.success("Download realizado com sucesso");
        dispatch(setLoadingQr(false));
      })
      .catch((e) => {
        setTimeout(() => {
          toast.error("Erro ao realizar download");
          dispatch(setLoadingQr(false));
        }, 500);
        console.log(e);
      });

  };

  const downloadQrCode = () => {
    dispatch(setLoadingQr(true));

    const canvas = document.getElementById("canvasQr");
    canvas.size = "500";
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    setTimeout(() => {
      dispatch(setLoadingQr(false));
    }, 500);
  };

  const copyQrcode = async () => {
    await copyTextToClipboard(`${process.env.REACT_APP_EXTERNA_SIGNATURE}/${userInfo.id}`)
    toast.success(t("preview.mensage"))
  }

  const changeLanguage = (event) => {
    dispatch(setTypeLanguage(event.value));
    i18n.changeLanguage(event.value);
  };

  useEffect(() => {
    switch (userRedux.typeLanguage) {
      case "pt":
        setlanguageActual(LogoBrasil);
        break;
      case "en":
        setlanguageActual(LogoEUA);
        break;
      case "es":
        setlanguageActual(LogoEspanha);
        break;
    }
  }, [userRedux.typeLanguage]);
  function RenderLogos() {
    return !userRedux?.logo.image ? (
      <></>
    ) : (<img style={{
      paddingRight: !removeQrCode ? "3.4em" : userRedux.logo.name == 'Rider' ? "4.0em" : "3.0em",
      marginLeft: stylesLogos(userRedux.logo.name).ml,
      width: stylesLogos(userRedux.logo.name).widht,
    }} src={userRedux.logo.image} />)

  }
  function ValidRender() {
    return !removeQrCode ? (
      <QrArea style={{ marginLeft: "-3em" }} className="d-flex justify-content-center align-items-center col-md-1">
        <QRCodeCanvas
          id="canvasQr"
          style={{
            width: "60px",
            height: "60px",
          }}
          size={500}
          renderAs="canvas"
          value={`${process.env.REACT_APP_EXTERNA_SIGNATURE}/${userInfo.id}`}
        />
      </QrArea>
    ) : (
      <QrAreaWhite id="semQr" className="d-flex justify-content-center align-items-center col-md-1" />
    )

  }
   return (
    <BodyPreview>
      <HeaderPreview>
        <TitlePreview> {t("preview.title")}</TitlePreview>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "0.5px",
          }}
        >
          <img src={languageActual} style={{ marginRight: "10px" }} />
          <Select
            defaultValue={options.filter((element) => element.value === userRedux.typeLanguage)}
            options={options}
            onChange={(e) => changeLanguage(e)}
            styles={styleSelect}
          />
        </div>
      </HeaderPreview>

      <Nav
        tabs
        className="d-flex justify-content-center"
        style={{ border: "none", cursor: "pointer" }}
      >
        <NavItem>
          <NavLink
            style={{
              color: userRedux.tab === "1" ? "#fff" : "#3542C2",
              marginBottom: "20px",
              borderRadius: "8px",
              border: "1px solid #3542C2",
              paddingInline: "35px",
              marginRight: "-10px",
              backgroundColor: userRedux.tab === "1" ? "#3542C2" : "transparent",
            }}
            className={userRedux.tab === "1" ? "active" : ""}
            onClick={() => {
              toggle("1");
            }}
          >
            {t("cardOnline.title")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{
              color: userRedux.tab === "2" ? "#fff" : "#3542C2",
              marginBottom: "20px",
              borderRadius: "8px",
              border: "1px solid #3542C2",
              paddingInline: "40px",
              backgroundColor: userRedux.tab === "2" ? "#3542C2" : "transparent",
            }}
            className={userRedux.tab === "2" ? "active" : ""}
            onClick={() => {
              toggle("2");
            }}
          >
            {t("emailSignature.title")}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent
        className="d-flex flex-direction-column justify-content-center align-items-center w-100"
        activeTab={userRedux.tab}
      >
        <TabPane tabId="1">
          <Row className="m-0 ">
            <Col sm="12">
              <BusinessCard>
                <HeaderBusinessCard>
                  <img
                    src={logoGrendene}
                    style={{
                      position: "relative",
                      top: "80px",
                    }}
                    alt="grendene-logo"
                  />
                  <ImageArea
                    style={{
                      background: `url(${userRedux.previewImage || BlankProfile}) center center`,
                    }}
                  />
                </HeaderBusinessCard>

                <ContentBusinessCard>
                  <span
                    style={{
                      font: "normal normal 800 14px/20px Montserrat",
                      color: "#19227D",
                    }}
                  >
                    {userRedux.username && userRedux.username?.length !== 0
                      ? userRedux.username
                      : ""}
                  </span>
                  <span
                    style={{
                      width: "215px",
                      textAlign: "center",
                      font: "normal normal medium 13px/16px Montserrat",
                      color: "#A4A1BE",
                    }}
                  >
                    {userRedux.role && userRedux.role?.length !== 0 ? userRedux.role : ""}
                    {userRedux.department && userRedux.department.length !== 0
                      ? ` | ${userRedux.department}`
                      : ""}
                  </span>
                  {/* <span
                    style={{
                      width: "215px",
                      textAlign: "center",
                      font: "normal normal medium 13px/16px Montserrat",
                      color: "#A4A1BE",
                    }}
                  >
                    {userRedux.unity && userRedux.unity?.length !== 0 ? userRedux.unity : ""}
                  </span> */}
                </ContentBusinessCard>

                <InfoItemsArea>
                  <InfoItems md={3}>
                    <LabelInfo> {t("cardOnline.telephone")}</LabelInfo>
                    <ImgCircle src={phone} />
                  </InfoItems>
                  <InfoItems md={3}>
                    <LabelInfo>{t("cardOnline.whatsapp")}</LabelInfo>
                    <ImgCircle src={whats} />
                  </InfoItems>
                </InfoItemsArea>

                <InfoItemsArea>
                  <InfoItems md={3}>
                    <LabelInfo>{t("cardOnline.email")}</LabelInfo>
                    <ImgCircle src={msg} />
                  </InfoItems>
                  <InfoItems md={3}>
                    <LabelInfo>{t("cardOnline.contact")}</LabelInfo>
                    <ImgCircle src={SafeContact} />
                  </InfoItems>
                </InfoItemsArea>
              </BusinessCard>

              <ButtonPrimary
                className="mt-5"
                disabled={userRedux.loadingQr}
                onClick={() => onlyQrCode ? copyQrcode() : downloadQrCode()}
                style={{ width: "75%" }}
              >
                {userRedux.loadingQr ? (
                  <div className="d-flex justify-content-center">
                    {" "}
                    <LoadinSpinner color="white" />{" "}
                  </div>
                ) :
                  onlyQrCode ? t("cardOnline.copyButton") : t("cardOnline.button")
                }
              </ButtonPrimary>

              <FormGroup className="my-2">
                <Input type="checkbox" id="only-qr-code" onChange={e => setOnlyQrCode(curr => !curr)} />
                <Label
                  htmlFor="only-qr-code"
                  className="mx-2"
                  style={{
                    font: "500 semibold 14px/20px",
                    color: "#3542C2",
                    cursor: "pointer",
                  }}
                >
                  {t("cardOnline.copy")}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="2" className="w-100">
          <Row className="m-0 h-100">
            <Col sm="12" className="m-0" style={{ height: "65vh" }}>
              <Card
                className="h-100"
                style={{
                  width: "100%",
                  background: "transparent",
                  border: "none",
                }}
              >
                <CardBody
                  id="qrCodePrint"
              
                  className="d-flex flex-direction-row align-items-center justify-content-center p-0"
                >

                  <QrCard id="qrCode" style={{
                    margin: userRedux.logo != "Sem Logo" ? "0.5em" : "0em",
                    
                  }} ref={textAreaRef} className="row">

                    <div className={`col-md-3 `} style={{ marginRight: removeQrCode ? "0.9em" : "1.3em" }}>
                      <InfoUserArea
                        className="col"
                        style={{
                          font: "normal normal medium 11px/19px Montserrat",
                          color: "#4B4B4B",
                          marginLeft: "1em"
                        }}
                      >
                        <div>
                          <RenderLogos />
                        </div>

                        <div>
                          <LogoGrendene style={{
                            paddingRight: !removeQrCode && userRedux?.logo?.name ? stylesGrendene(userRedux.logo).pr : stylesGrendene(userRedux.logo).pr,
                            marginRight: !removeQrCode ? "2em" : stylesGrendene(userRedux.logo).mr,
                            marginLeft: !removeQrCode && userRedux.logo.name == undefined || userRedux.logo.name == 'default' ? "-1.3em" : "-0.1em",
                            marginTop: stylesGrendene(userRedux.logo).mt,
                            width: stylesGrendene(userRedux.logo).widht
                          }} src={logoGrendenePreta}></LogoGrendene>
                        </div>

                      </InfoUserArea>
                    </div>

                    <div
                      style={{
                        padding: 0,
                        boxShadow: "3px 0 5px 1px rgba(0, 0, 0, .25)",
                        width: "1px",
                        height: "55%",
                      }}
                    ></div>

                    <InfoUserArea
                      className="col"
                      style={{
                        font: "normal normal medium 11px/19px Montserrat",
                        color: "#4B4B4B",
                        marginLeft: "1em"
                      }}
                    >
                      <span
                        style={{
                          font: "normal normal bold 18px/20px Arial",
                          textAlign: "left",
                          width: "25ch",
                        }}
                      >
                        {userRedux.username && userRedux.username?.length !== 0
                          ? userRedux.username
                          : ""}
                      </span>

                      <span style={styles.signatureText}>
                        {userRedux.role && userRedux.role?.length !== 0 ? userRedux.role : ""}
                      </span>
                      <span style={styles.signatureText}>
                        {userRedux.department && userRedux.department?.length !== 0
                          ? `  ${userRedux.department}`
                          : ""}
                      </span>
                      <span style={styles.signatureText}>
                        {t('form.unity')}{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {userRedux.unity && userRedux.unity?.length !== 0 ? userRedux.unity : ""}
                        </span>
                      </span>
                      <span style={styles.signatureText}>
                        {userRedux.phone && userRedux.phone?.length !== 0 ? userRedux.phone : ""}
                      </span>
                    </InfoUserArea>
                    <ValidRender />

                  </QrCard>
                </CardBody>

                <CardFooter
                  className="p-1"

                  style={{
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <FormGroup className="my-2">
                    <Input type="checkbox" id="only-qr-code" onChange={e => setRemoveOnlyQrCode(curr => !curr)} />
                    <Label
                      htmlFor="only-qr-code"
                      className="mx-2"
                      style={{
                        font: "500 semibold 14px/20px",
                        color: "#3542C2",
                        cursor: "pointer",
                      }}
                    >
                      {t("emailSignature.removeQr")}
                    </Label>
                  </FormGroup>


                </CardFooter>
              </Card>
              <ButtonPrimary onClick={() => downloadSignature()} style={{ width: "75%" }}>
                {userRedux.loadingQr ? (
                  <div className="d-flex justify-content-center">
                    <LoadinSpinner color="white" />
                  </div>
                ) : (
                  t("emailSignature.button")
                )}
              </ButtonPrimary>
            </Col>
          </Row>
          <HeaderPreview
            style={{
              justifyContent: "center",
            }}
          >
            <Questions>
              {t("preview.description")}?{" "}
              <b
                onClick={() => window.open(`${process.env.REACT_APP_EXTERNA_LINK}`)}
                style={{ fontWeight: "bold", cursor: "pointer" }}
              >
                {t("preview.link")}
              </b>
            </Questions>
          </HeaderPreview>
        </TabPane>
      </TabContent>


    </BodyPreview >
  );
}

const styles = {
  signatureText: {
    // fontSize: "smaller",
    width: "25ch",
    textAlign: "left",
  },
};
