import React from "react";
import logoGrendene from "../../images/grendene_logo.svg";
import office365 from "../../images/office_365.png";
import {
  Office365Button,
  LabelLogin,
  ParentContent,
  RightContent,
  LeftContent,
  RightContentCenter,
} from "../../components/LoginStyle";
import { useState } from "react";
import LoadinSpinner from "../../components/LoadinSpinner";

function Login() {
  const [loading, setLoading] = useState(false);

  const haddleClick = () => {
    setLoading(!loading);
    window.location.replace(process.env.REACT_APP_REDIRECT_AZURE_PROVIDER);
  };

  return (
    <ParentContent>
      <LeftContent></LeftContent>
      <RightContent>
        <RightContentCenter>
          <img
            src={logoGrendene}
            alt="Logo grendene"
            style={{ objectFit: "cover", width: 300, height: 50 }}
          />
          <LabelLogin>Login</LabelLogin>
          <Office365Button onClick={haddleClick}>
            {!loading ? (
              <>
                <img src={office365} alt="office" style={{ paddingRight: "7px" }} />
                Entrar com Office 365
              </>
            ) : (
              <LoadinSpinner color="#2A3888" />
            )}
          </Office365Button>
        </RightContentCenter>
      </RightContent>
    </ParentContent>
  );
}

export default Login;
