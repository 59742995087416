const axios = require('axios').default
const FILE_SERVER_URL = process.env.REACT_APP_FILE_SERVER_URL



export async function minioUploadFile(file, type, customHeader) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    const options = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'type': type,
            ...customHeader,
        }
    };

    return await axios.post(`${FILE_SERVER_URL}/upload`, formData, options).catch(e => console.log(e))
}
export async function getByUserImg(fileName) {

    return await axios({

        method: 'get',
        url: `${FILE_SERVER_URL}/download?filename=${fileName}&type=user`,
        responseType: 'blob'

    }).catch(e => console.log(e))

}