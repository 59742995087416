import styled from "styled-components";
import bubbles from '../images/bubbles.svg';
import grendene_fabrica from '../images/grendene_f2.png';


export const Office365Button = styled.button`
    color: #6980F8;
    background-color: #F2F2F2;
    border: none;
    font-weight: 600;
    padding: 5px 25px;
    border-radius: 20px;
    width: 220px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &:hover{
        background-color: #e3e3e3;
      }
`

export const LabelLogin = styled.div`
    color: white;
    padding: 20px 20px;
    font-size: 25px;
    font-weight: 600 
`

export const ParentContent = styled.div`
    display: flex;
    height: 100vh;
    flex-wrap: wrap
`

export const LeftContent = styled.div`
    flex: 6;
    background-color: gray;
    background-image: url(${grendene_fabrica});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
`

export const RightContent = styled.div`
    flex: 4;
    height: 100%;
    background-color: #2A3888;
    background-image: url(${bubbles});
    background-repeat: no-repeat;
    background-position: bottom
`

export const RightContentCenter = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px
`


