import { Button, Col, FormGroup, Label, NavLink, Row } from "reactstrap";
import styled from "styled-components";

//_________________________________________ STYLE PADRÃO_______________________________________________________
export const ButtonPrimary = styled(Button)`
background: #3542C2;
border-radius: 15px;
opacity: 1;
width: 100%;
border: none;
font-family: "Montserrat";
`;

//_________________________________________ STYLE APPBAR_______________________________________________________
export const Header = styled(Row)`
height: 50px;
background-color: #19227D;
margin:0;
`;

export const LogoImg = styled.img`

`;

export const ExitImg = styled.img`
width: 30px
`;

//__________________________________________STYLE REGISTER____________________________________________________
export const BodyRegister = styled.div`
margin: 0;
background-color: #F2F2F2;
`;

export const PictureArea = styled.div`
width: 150px;
height: 150px;
background-color: transparent;
border: 4px solid #3542C2;
color: #FFFFFF;
border-radius: 50%;
margin: 0px auto;
overflow: hidden;
transition: all 0.2s;
-webkit-transition: all 0.2s;
`;

export const Picture = styled.img`
width: 130px;
height: 130px;

margin-bottom:20px;
`;

export const FormArea = styled(FormGroup)`
display: flex;
flex-direction: column;
align-items: flex-start;
`;

export const LabelRegister = styled(Label)`
text-align: left;
font-style: normal;
font-variant: normal;
font-weight: 600;
font-size: medium;
font-family: "Montserrat";
color: #3542C2;
opacity: 1;
`;



//_________________________________________________STYLE PREVIEW__________________________________________________

export const BodyPreview = styled.div`
margin: 0;
background-color: #F8F8F8;
height: 100%;
`;

export const HeaderPreview = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
padding: 15px 20px 15px 20px;
`;

export const TitlePreview = styled.span`
text-align: left;
font: normal normal 600 20px Montserrat;
letter-spacing: 0px;
color: #3542C2;
opacity: 1;
`;

export const Questions = styled.span`
margin-top:3em;
font: normal normal 300 15px Montserrat;
letter-spacing: 0px;
color: #3542C2;
opacity: 1;
`;

export const NavLinkPreview = styled(NavLink)`
color: #3542C2;
margin-bottom: 20px;
border-radius: 8px;
border: 1px solid #3542C2;
`;

export const BusinessCard = styled.div`
width: 300px;
height: 27em;
background: #F2F2F2 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 0 0 26px 26px;
overflow: hidden;

`;

export const HeaderBusinessCard = styled.div`
height: 177px;
background-color: #19227D;
border-radius: 100%;
position: relative;
top: -60px;
right: 18px;
width: 346px;
`;

export const ImageArea = styled.div`
top: 97px;
left: 111px;
width: 110px;
height: 110px;
position: relative;
border: 3px solid #19227d;
border-radius: 100%;
background-size:cover!important;
`;

export const ContentBusinessCard = styled.div`
display: flex;
flex-direction: column;
align-content: center;
justify-content: center;
align-items: center;
`;

export const InfoItemsArea = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
margin-top: 18px;
`;

export const InfoItems = styled(Col)`
display: flex;
flex-direction: column;
-webkit-box-pack: center;
-webkit-box-align: center;
align-items: center;
`;

export const LabelInfo = styled.span`
text-align: center;
font-style: normal;
font-variant: normal;
font-size: 12px;
font-family: "Montserrat";
letter-spacing: 0px;
color: #19227D;
`;

export const ActionInfo = styled(Button)`
background-color: transparent;
border: none;
&:hover {
    background-color: transparent;
}
`;

export const ImgCircle = styled.img`
width: 60%;
height: 72%;
`;

export const QrCard = styled.div`
display: flex;
flex-direction: row;
align-content: center;
align-items: center;
width: auto;
height: 196px;
background: #F2F2F2 0% 0% no-repeat padding-box;
border-radius: 26px;
padding-inline:30px;
@media (max-width: 1200px) {
    flex-wrap: nowrap;
    width: 105%;
}
`;

export const LogoGrendene = styled.img`
width: 160px;
padding-right:0.3em;
`;

export const Separator = styled.div`
max-width: 1px;
height: 100px;
background-color: #707070;
margin-inline: 20px;
`;

export const InfoUserArea = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;

span{
    color:black
}
`;

export const QrArea = styled.div`
width: 104px;
height: 100px;
background: #E1E1E1 0% 0% no-repeat padding-box;
border-radius: 30px;
margin-left:25px
`;
export const QrAreaWhite = styled.div`
width: 104px;
height: 100px;
background: #white 0% 0% no-repeat padding-box;
border-radius: 0px;
margin-left:0px
`;


