import { t } from "i18next"
import logoAzaleia from "../assets/Azaleia.svg"
import logoCartago from '../assets/Cartago.svg'
import logoGrendha from '../assets/Gredha.svg'
import logoIpanema from '../assets/Ipanema.svg'
import logoKids from "../assets/GKids.svg"
import logoMelissa from "../assets/Melissa.svg"
import logoRider from "../assets/Rider.svg"
import logoZaxy from "../assets/Zaxy.svg"
import logoCopacabana from "../assets/Copacabana.svg"
import logoPegaForte from "../assets/PegaForte.svg"

export const optionsLogos = [
    { "id": 0, image: null, name: 'default' },
    { "id": 1, image: logoAzaleia, name: "Azaleia" },
    { "id": 2, image: logoCartago, name: "Cartago" },
    { "id": 3, image: logoGrendha, name: "Grendha" },
    { "id": 4, image: logoIpanema, name: "Ipanema" },
    { "id": 5, image: logoKids, name: "Kids" },
    { "id": 6, image: logoMelissa, name: "Melissa" },
    { "id": 7, image: logoRider, name: "Rider" },
    { "id": 8, image: logoZaxy, name: "Zaxy" },
    { "id": 9, image: logoCopacabana, name: "Copacabana" },
    { "id": 10, image: logoPegaForte, name: "Pega Forte" }
]
export const stylesLogos = (logo) => {
    return logo == 'Melissa' || logo == 'Rider' || logo == 'Zaxy' ? {
        widht: '230px',
        ml: "-1.8em"
    } : logo == 'Copacabana' ?
        {
            widht: '170px',
            ml: "-0.3em"
        }
        : {
            widht: '200px',
            ml: "-1.2em"
        }
}
export const stylesGrendene = (logo) => {

    return !logo.image ? {
        widht: '150px',
        mr: "3em",
        mt: '-0.5em',
        pr: '1.8em'
    } : {
        widht: '145px',
        mt: '-1.5em',
        pr: '1.8em'
    }
}