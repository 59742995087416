import React from 'react'
import logoGrendene from '../images/grendene_logo.png'
import exitImg from '../images/exitIcon.svg'
import { ExitImg, Header, LogoImg } from './style'
import { Col, Row } from 'reactstrap'



function AppBar() {
  function logout() {
    return window.location.replace("/");
  }

  return (
    <Header className="row justify-content-between align-items-center">
      <Col xs={2}>
        <LogoImg src={logoGrendene} alt="Logo" style={{ objectFit: "cover", width: 300, height: 50 }} />
      </Col>
      <Col xs={2}>
        <ExitImg src={exitImg} alt="Exit" onClick={logout} style={{ cursor: "pointer" }} />
      </Col>
    </Header>
  )
}

export default AppBar