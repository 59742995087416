let cookies = []

export const deleteAllCookies = () => {
	var cookies = document.cookie.split(';')

	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i]
		var eqPos = cookie.indexOf('=')
		var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
	}
}

export const updateCookiesArray = () => {
	const response = document.cookie.split(';')
	response.map((cookie) => {
		const res = cookie.split('=')
		cookies.push({
			key: res[0].split(' ').join(''),
			value: atob(res[1]),
		})
	})
}

export const getCookieInArray = (key) => cookies.find((cookie) => cookie.key == key)

export function getCookie(name) {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${name}=`)
	if (parts.length === 2) return parts.pop().split(';').shift()
}

export function getCookieString(name) {
	const cookie = getCookie(name)
	if (!cookie) return null
	const cookieString = atob(cookie)
	return cookieString
}