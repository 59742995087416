import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApolloProvider } from '@apollo/client';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import createApolloClient from './service/apolloClient';
import store from './redux/store'
import { Provider } from 'react-redux'
import { isAuthenticated, verifyLogin365, auth } from "./auth"

function App() {

  const client = createApolloClient(auth.currentTokenValue)
  // const client = createApolloClient("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJYLUhhc3VyYS1Vc2VyLUlkIjoiMGE3MGZjNzMtYzE1Mi00OWY4LWI4NWYtM2U4NWUyMjU0YzVjIiwiWC1IYXN1cmEtVXNlci1FbWFpbCI6ImdhYnJpZWwucGF6QGJlYW5zb2Z0d2FyZXMuY29tLmJyIiwiWC1IYXN1cmEtUm9sZSI6ImF6dXJlLXVzZXIiLCJpYXQiOjE2ODgzODU4MjEsImV4cCI6MTY5MTAxMzgyMX0.Bm7SjvUmG7LsxLdWFOL_a-wmgkO8VV3oj0nfKDxTxGk")

  return (
    <div className="App">
      <BrowserRouter>
        <ApolloProvider client={client}>

          <Provider store={store}>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/home' element={<Home />} />
              {/* {
                isAuthenticated() 
                  ? <Route path='/home' element={<Home />} />
                  : <></>
              } */}
            </Routes>
          </Provider>
        </ApolloProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
